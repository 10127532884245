<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Edit appoinment')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.name"
                  class="mt-2"
                  :state="errors.name ?false:null"
                  :placeholder="$t('name')"
                />
                <small
                  v-if="errors.name"
                  class="text-danger"
                >{{ errors.name[0] }}</small>
                <label>{{ $t('name') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.description"
                  class="mt-2"
                  :state="errors.description ?false:null"
                  :placeholder="$t('description')"
                />
                <small
                  v-if="errors.description"
                  class="text-danger"
                >{{ errors.description[0] }}</small>
                <label>{{ $t('description') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.lat"
                  class="mt-2"
                  :state="errors.lat ?false:null"
                  :placeholder="$t('lat')"
                />
                <small
                  v-if="errors.lat"
                  class="text-danger"
                >{{ errors.lat[0] }}</small>
                <label>{{ $t('lat') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.lan"
                  class="mt-2"
                  :state="errors.lan ?false:null"
                  :placeholder="$t('lan')"
                />
                <small
                  v-if="errors.lan"
                  class="text-danger"
                >{{ errors.lan[0] }}</small>
                <label>{{ $t('lan') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.status"
                  class="mt-2"
                  :state="errors.status ?false:null"
                  :placeholder="$t('status')"
                />
                <small
                  v-if="errors.status"
                  class="text-danger"
                >{{ errors.status[0] }}</small>
                <label>{{ $t('status') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-card>
          <b-row>
            <b-col
              cols="12"
            >
              <b-button
                class="w-100 mb-1"
                variant="gradient-success"
                @click="update"
              >
                {{ $t('Update') }}
              </b-button>
              <b-button
                class="w-100"
                variant="gradient-danger"
                @click="data={},errors=[]"
              >
                {{ $t('Reset Form') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {

    Element() { return this.$store.getters['appoinment/GetElement'] },
  },
  created() {
    this.getData()
  },
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('appoinment/GetElement', this.$route.params.appoinment).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.errors = []
      this.$store.dispatch('appoinment/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
